.application {
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .application .div {
    background-color: var(--cbglightgray);
    border: 2px none;
    overflow: hidden;
    position: relative;
    width: 780px;
    top:-176px;
  }
  
  .application .overlap-group-wrapper {
    height: 88px;
    left: 0;
    position: absolute;
    top: 88px;
    width: 784px;
  }
  
  .application .overlap-group {
    background-color: #ffffff;
    height: 88px;
    position: relative;
    width: 780px;
  }
  
  .application .icon-fanhui {
    height: 56px !important;
    left: 32px !important;
    position: absolute !important;
    top: 16px !important;
    width: 56px !important;
  }
  
  .application .text-wrapper {
    color: var(--ctextwbgprimary-90);
    font-family: var(--font-16-24-medium-font-family);
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 184px;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    text-align: center;
    top: 20px;
    white-space: nowrap;
  }
  
  .application .view {
    height: 56px;
    left: 692px;
    position: absolute;
    top: 16px;
    width: 56px;
  }
  
  .application .div-2 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 24px;
    position: absolute;
    top: 24px;
    width: 6px;
  }
  
  .application .div-3 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 12px;
    position: absolute;
    top: 24px;
    width: 6px;
  }
  
  .application .div-4 {
    background-color: #191919;
    border-radius: 3px;
    height: 6px;
    left: 40px;
    position: absolute;
    top: 24px;
    width: 6px;
  }
  
  .application .iphone-x-status-bars {
    background-color: #ffffff;
    height: 88px;
    left: 0;
    position: absolute;
    top: 0;
    width: 780px;
  }
  
  .application .battery {
    height: 24px;
    left: 694px;
    position: absolute;
    top: 36px;
    width: 48px;
  }
  
  .application .wifi {
    height: 24px;
    left: 650px;
    position: absolute;
    top: 36px;
    width: 32px;
  }
  
  .application .cellular-connection {
    height: 22px;
    left: 604px;
    position: absolute;
    top: 38px;
    width: 32px;
  }
  
  .application .time-style {
    height: 36px;
    left: 42px;
    overflow: hidden;
    position: absolute;
    top: 30px;
    width: 108px;
  }
  
  .application .time {
    color: #000000;
    font-family: "SF Pro Text-Semibold", Helvetica;
    font-size: 32px;
    font-weight: 400;
    left: 20px;
    letter-spacing: -1.2px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .application .group {
    height: 68px;
    left: 0;
    position: absolute;
    top: 1928px;
    width: 780px;
  }
  
  .application .home-indicator-light {
    height: 68px;
    overflow: hidden;
  }
  
  .application .home-indicator-wrapper {
    background-color: #ffffff;
    height: 72px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 784px;
  }
  
  .application .home-indicator {
    background-color: #000000;
    border-radius: 5px;
    height: 14px;
    left: 252px;
    position: relative;
    top: 42px;
    width: 280px;
  }
  
  .application .group-wrapper {
    
    width: 700px;
    margin-left: 40px;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 30px;
    padding-bottom: 40px;
  }
  
  .application .group-2 {
    height: 196px;
    left: 40px;
    position: relative;
    top: 32px;
    width: 620px;
  }
  
  .application .text-wrapper-2 {
    color: var(--ctextwbgprimary-90);
    font-family: var(--font-16-24-medium-font-family);
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 0;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .application .text-wrapper-3 {
    color: var(--ctextwbgnormal-60);
    font-family: var(--font-14-22-regular-font-family);
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 0;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    text-align: justify;
    top: 64px;
    text-align: left;
  }
  
  .application .overlap-wrapper {
    width: 700px;
    margin-top: 380px;
    margin-left: 40px;
    background-color: #fff;
    border-radius: 20px;
  }
  
  .application .overlap {
    position: relative;
    width: 700px;
    background-color: #fff;
  }
  
  .application .li-wrapper {
    background-image: url(../../../public/img/b-bg2.png);
    background-size: 100% 100%;
    height: 394px;
    left: 0;
    position: absolute;
    top: 0;
    width: 700px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  .application .li {
    height: 36px;
    left: 262px;
    position: absolute;
    top: 72px;
    width: 176px;
  }
  
  .application .text-wrapper-4 {
    color: var(--ctextwbgprimary-90);
    font-family: var(--font-16-24-medium-font-family);
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    left: 40px;
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    text-align: center;
    top: 426px;
    width: 620px;
  }
  
  .application .text-wrapper-5 {
    color: var(--ctextwbgprimary-90);
    font-family: var(--font-14-22-regular-font-family);
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    width: 700px;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    position: absolute;
    text-align: center;
    top: 1006px;
    white-space: nowrap;
  }
  
  .application .group-3 {
    height: 388px;
    left: 180px;
    position: absolute;
    top: 586px;
    width: 344px;
  }
  
  .application .text-wrapper-6 {
    color: var(--ctextwbgnormal-60);
    font-family: var(--font-12-20-regular-font-family);
    font-size: var(--font-12-20-regular-font-size);
    font-style: var(--font-12-20-regular-font-style);
    font-weight: var(--font-12-20-regular-font-weight);
    /* left: 108px; */
    letter-spacing: var(--font-12-20-regular-letter-spacing);
    line-height: var(--font-12-20-regular-line-height);
    position: absolute;
    text-align: center;
    top: 348px;
    white-space: nowrap;
    width: 100%;
    text-align: center;
  }
  
  .application .mask-group {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .application .group-4 {
    height: 56px;
    left: 210px;
    position: absolute;
    top: 256px;
    width: 362px;
  }
  
  .application .text-wrapper-7 {
    color: #000000;
    font-family: var(--font-20-28-medium-font-family);
    font-size: var(--font-20-28-medium-font-size);
    font-style: var(--font-20-28-medium-font-style);
    font-weight: var(--font-20-28-medium-font-weight);
    left: 72px;
    letter-spacing: var(--font-20-28-medium-letter-spacing);
    line-height: var(--font-20-28-medium-line-height);
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .application .image {
    height: 56px;
    left: 0;
    position: absolute;
    top: 0;
    width: 56px;
  }
  
  .application .div-wrapper {
    height: 98px;
    left: 0;
    position: absolute;
    top: 1830px;
    width: 800px;
  }
  
  .application .overlap-2 {
    background-color: #ffffff;
    height: 102px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 784px;
  }
  
  .application .overlap-3 {
    height: 88px;
    left: 508px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .application .view-2 {
    height: 56px;
    left: 14px;
    position: absolute;
    top: 0;
    width: 56px;
  }
  
  .application .overlap-group-2 {
    height: 36px;
    left: 10px;
    position: relative;
    top: 12px;
    width: 40px;
  }
  
  .application .rectangle {
    height: 4px;
    left: 0;
    position: absolute;
    top: 32px;
    width: 40px;
  }
  
  .application .img {
    height: 36px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 36px;
  }
  
  .application .text-wrapper-8 {
    color: #000000e6;
    font-family: "LiciumFont 2022-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 36px;
    opacity: 0.9;
    position: absolute;
    text-align: center;
    top: 52px;
    white-space: nowrap;
  }
  
  .application .overlap-4 {
    height: 88px;
    left: 350px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .application .overlap-5 {
    height: 88px;
    left: 38px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .application .overlap-6 {
    height: 88px;
    left: 194px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .application .overlap-7 {
    height: 36px;
    left: 8px;
    position: relative;
    top: 10px;
    width: 40px;
  }
  
  .application .rectangle-2 {
    height: 32px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 36px;
  }
  
  .application .vector {
    height: 4px;
    left: 10px;
    position: absolute;
    top: 8px;
    width: 20px;
  }
  
  .application .vector-2 {
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px;
  }
  
  .application .vector-3 {
    height: 8px;
    left: 18px;
    position: absolute;
    top: 28px;
    width: 4px;
  }
  
  .application .vector-4 {
    height: 4px;
    left: 10px;
    position: absolute;
    top: 16px;
    width: 8px;
  }
  
  .application .overlap-8 {
    height: 88px;
    left: 664px;
    position: absolute;
    top: 6px;
    width: 82px;
  }
  
  .application .img-2 {
    height: 32px;
    left: 8px;
    position: absolute;
    top: 12px;
    width: 40px;
  }
  
  .application .img-3 {
    height: 2446px;
    left: -8864px;
    position: absolute;
    top: 6010px;
    width: 780px;
  }
  
  .application .img-4 {
    height: 1688px;
    left: -7122px;
    position: absolute;
    top: 6010px;
    width: 780px;
  }
  
  .application .img-5 {
    height: 1688px;
    left: -6254px;
    position: absolute;
    top: 6010px;
    width: 780px;
  }
  
  .application .img-6 {
    height: 4236px;
    left: -7996px;
    position: absolute;
    top: 6010px;
    width: 780px;
  }

  .application .logo{
    position: absolute;
    top: 80px;
    width: 160px;
    left: 260px;
  }

  .application .text-wrapper-wanyan{
    color: var(--ctextwbgprimary-90);
    font-family: var(--font-16-24-medium-font-family);
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
   
    text-align: center;
    top: 1046px;
    white-space: nowrap;
    width: 700px;
  }

  .application .text-wrapper-dahui{
    
    text-align: center;
    top: 1086px;
    white-space: nowrap;

    color: var(--ctextwbgprimary-90);
    font-family: var(--font-16-24-medium-font-family);
    font-size: var(--font-16-24-medium-font-size);
    font-style: var(--font-16-24-medium-font-style);
    font-weight: var(--font-16-24-medium-font-weight);
    letter-spacing: var(--font-16-24-medium-letter-spacing);
    line-height: var(--font-16-24-medium-line-height);
    position: absolute;
    width: 700px;
   
  }
  
  .application .shuoming{
    color: var(--ctextwbgnormal-60);
    font-family: var(--font-14-22-regular-font-family);
    font-size: var(--font-14-22-regular-font-size);
    font-style: var(--font-14-22-regular-font-style);
    font-weight: var(--font-14-22-regular-font-weight);
    left: 0;
    letter-spacing: var(--font-14-22-regular-letter-spacing);
    line-height: var(--font-14-22-regular-line-height);
    
    text-align: justify;
    text-align: left;
    padding: 40px;
    padding-top: 1150px;
    word-break: break-all;
  }