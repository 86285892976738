.login {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

}


.login .overlap-wrapper {
  background-color: var(--cbglightgray);
  border: 1px none;
  height: 1688px;
  width: 780px;
}

.login .overlap {

  background-color: #f9f9f9;
  border-radius: 16px;
  height: 340px;
  position: relative;
  width: 700px;
}

.login .element-l {
  height: 1688px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 780px;
}

.login .iphone-x-status-bars {
  height: 88px;
  left: 0;
  position: absolute;
  top: 0;
  width: 780px;
}

.login .battery {
  height: 24px;
  left: 694px;
  position: absolute;
  top: 36px;
  width: 48px;
}

.login .wifi {
  height: 24px;
  left: 650px;
  position: absolute;
  top: 36px;
  width: 32px;
}

.login .cellular-connection {
  height: 22px;
  left: 604px;
  position: absolute;
  top: 38px;
  width: 32px;
}

.login .time-style {
  height: 36px;
  left: 42px;
  overflow: hidden;
  position: absolute;
  top: 30px;
  width: 108px;
}

.login .time {
  color: #ffffff;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 20px;
  letter-spacing: -1.2px;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.login .text-wrapper {
  color: var(--ctextbbgprimary-100);
  font-family: "LiciumFont 2022-Regular", Helvetica;
  font-size: 56px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 80px;
  position: absolute;
  text-align: center;
  top: 320px;
  width: 780px;
}

.login .group {
  background-image: url(../../../public/img/img2/logo.png);
  background-size: 100% 100%;
  height: 40px;
  left: 290px;
  position: absolute;
  top: 256px;
  width: 198px;
}

.login .overlap-group-wrapper {
  height: 540px;
  left: 40px;
  position: absolute;
  top: 920px;
  width: 704px;
}

.login .overlap-group {
  background-color: #ffffff;
  border-radius: 16px;
  height: 540px;
  position: relative;
  width: 700px;
}

.login .div {
  height: 324px;
  left: 0;
  position: absolute;
  top: 0;
  width: 700px;
}

.login .view {
  height: 108px;
  left: 0;
  position: absolute;
  top: 216px;
  width: 712px;
}

.login .overlap-group-2 {
  height: 108px;
  position: relative;
  width: 700px;
}

.login .image {
  height: 108px;
  left: 0;
  position: absolute;
  top: 0;
  width: 700px;
}

.login .text-wrapper-2 {
  color: var(--ctextwbgprimary-90);
  font-family: var(--font-14-22-regular-font-family);
  font-size: var(--font-14-22-regular-font-size);
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 40px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);
  position: absolute;
  top: 32px;
  white-space: nowrap;
}

.login .text-wrapper-3 {
  color: var(--ctextwbgplaceholder-20);
  font-family: var(--font-14-22-regular-font-family);
  font-size: var(--font-14-22-regular-font-size);
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 292px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);
  position: absolute;
  top: 32px;
  white-space: nowrap;
}

.login .text-wrapper-4 {
  color: #5A7CAB;
  font-family: var(--font-14-22-regular-font-family);
  font-size: var(--font-14-22-regular-font-size);
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 516px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);
  position: absolute;
  text-align: right;
  top: 32px;
  white-space: nowrap;

}

.login .img {
  height: 2px;
  left: 40px;
  object-fit: cover;
  position: absolute;
  top: 108px;
  width: 660px;
}

.login .group-2 {
  height: 216px;
  left: 0;
  position: absolute;
  top: 0;
  width: 700px;
}

.login .overlap-2 {
  height: 108px;
  left: 0;
  position: absolute;
  top: 108px;
  width: 708px;
}

.login .div-wrapper {
  height: 108px;
  left: 0;
  position: absolute;
  top: 0;
  width: 708px;
}

.login .overlap-group-3 {
  background-image: url(../../../public/img/login/overlap-group-3.svg);
  background-size: 100% 100%;
  height: 108px;
  position: relative;
  width: 700px;
}

.login .overlap-3 {
  background-image: url(../../../public/img/login/overlap-3.svg);
  background-size: 100% 100%;
  height: 88px;
  left: 40px;
  position: absolute;
  top: 404px;
  width: 620px;
  border-radius: 44px;
}

.login .text-wrapper-5 {
  color: var(--ctextbbgprimary-100);
  font-family: var(--font-14-22-regular-font-family);
  font-size: var(--font-14-22-regular-font-size);
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 280px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);
  position: absolute;
  text-align: center;
  top: 22px;
  white-space: nowrap;
}

.login .rectangle {
  background-color: var(--cmaskbg);
  height: 1688px;
  left: 0;
  position: absolute;
  top: 0;
  width: 780px;
}

.login .overlap-wrapper-2 {
  height: 300px;
  left: 110px;
  position: absolute;
  top: 694px;
  width: 568px;
}

.login .overlap-4 {
  background-image: url(../../../public/img/login/overlap-4.svg);
  background-size: 100% 100%;
  height: 300px;
  position: relative;
  width: 560px;
}

.login .text-wrapper-6 {
  color: var(--ctextwbgnormal-60);
  font-family: var(--font-14-22-regular-font-family);
  font-size: var(--font-14-22-regular-font-size);
  font-style: var(--font-14-22-regular-font-style);
  font-weight: var(--font-14-22-regular-font-weight);
  left: 40px;
  letter-spacing: var(--font-14-22-regular-letter-spacing);
  line-height: var(--font-14-22-regular-line-height);
  position: absolute;
  text-align: center;
  top: 40px;
  width: 480px;
}

.login .image-2 {
  height: 2px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 212px;
  width: 560px;
}

.login .text-wrapper-7 {
  color: var(--ctextlink);
  font-family: var(--font-16-24-regular-font-family);
  font-size: var(--font-16-24-regular-font-size);
  font-style: var(--font-16-24-regular-font-style);
  font-weight: var(--font-16-24-regular-font-weight);
  width:560px;
  letter-spacing: var(--font-16-24-regular-letter-spacing);
  line-height: var(--font-16-24-regular-line-height);
  position: absolute;
  text-align: center;
  top: 232px;
  white-space: nowrap;
}

.login .err {
  position: absolute;
  top: 70px;
  font-size: 20px;
  left: 40px;
  color: #c51d1d;
}

.login input {
  border: none;
  color: #333;
}

.login .err-code {
  position: absolute;
  font-size: 20px;
  top: 70px;
  color: #c51d1d;
  right: 32px;
}

.login .err-login {
  position: absolute;
  bottom: 160px;
  font-size: 20px;
  left: 40px;
  color: #c51d1d;
}

.login .logo{
  position: absolute;
  top: 200px;
  width: 200px;
  left: 280px;
}